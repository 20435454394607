<template>
  <div class="container page">
    <van-nav-bar :title="$t('xiu-gai-deng-lu-mi-ma')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="save()">{{ $t('bao-cun') }}</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="old_password" :label="$t('jiu-mi-ma')" :placeholder="$t('qing-shu-ru-nin-de-jiu-mi-ma')" />
      <van-field v-model="o_new_password" :label="$t('xin-mi-ma')" :placeholder="$t('qing-shu-ru-nin-de-xin-mi-ma')" />
      <van-field v-model="t_new_password" :label="$t('xin-mi-ma-0')" :placeholder="$t('qing-zai-ci-shu-ru-nin-de-xin-mi-ma')" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      o_new_password:"",
      t_new_password:"",
      old_password:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      if(this.o_new_password === "" || this.o_new_password === null || this.o_new_password === undefined){
        this.$toast.fail(this.$t('qing-tian-xie-wan-zheng'));
        return false;
      }
      if(this.t_new_password === "" || this.t_new_password === null || this.t_new_password === undefined){
        this.$toast.fail(this.$t('qing-tian-xie-wan-zheng-0'));
        return false;
      }
      if(this.old_password === "" || this.old_password === null || this.old_password === undefined){
        this.$toast.fail(this.$t('qing-tian-xie-wan-zheng-1'));
        return false;
      }
      if(this.o_new_password !== this.t_new_password){
        this.$toast(this.$t('liang-ci-mi-ma-shu-ru-bu-yi-zhi'));
        return false;
      }
      this.$http({
        method: 'get',
        data:{
          old_password:this.old_password,
          new_password:this.o_new_password,
        },
        url: 'user_set_loginpw'
      }).then(res=>{
        if(res.code === 200){
          this.$toast(res.msg);
          setTimeout(() => {
            localStorage.clear()
            this.$router.push("Login")
          }, 500);

        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 30px;
  line-height: 80px;
}

::v-deep .van-field__label {
  width: 120px !important;
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  .van-cell {
    padding: 20px !important;
    line-height: normal !important;
  }

  ::v-deep .van-field__label {
    width: fit-content !important;
  }
}
</style>