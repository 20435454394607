<template>
  <div class="container page">
    <van-nav-bar :title="$t('tian-xie-shou-kuan-ka')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <!-- <div class="label">请输入您的收款卡信息</div> -->
      <van-cell-group>
        <van-field v-model="bankid" :label="$t('yin-hang-ka-hao')" type="digit" :placeholder="$t('qing-shu-ru-zhen-shi-yin-hang-ka-hao')" />
        <van-field v-model="bank" :label="$t('yin-hang-ming-cheng')" readonly :placeholder="$t('qing-xuan-ze-yin-hang')" @click="showSelectBanks()"/>
      </van-cell-group>
      <p>{{ $t('zun-jing-de-yong-hu-wei-le-bao-zhang-nin-de-zi-jin-an-quan-qing-nin-bang-ding-nin-de-zhen-shi-xing-ming-he-she-zhi-qu-kuan-mi-ma-ru-guo-xing-ming-yu-kai-hu-ming-bu-yi-zhi-jiang-wu-fa-qu-kuan') }}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{ $t('que-ren-bang-ka') }}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      bank:""
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.userInfo.bankid){
        this.$toast(this.$t('qing-shu-ru-yin-hang-ka-hao'));
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail(this.$t('qing-xuan-ze-yin-hang-0'));
        return false;
      }
      this.$http({
        method: 'post',
        data:{bankid:this.bankid,bank:this.bank},
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>

@import "../../assets/css/base.css";
.van-cell {
  font-size: 30px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 70px;
}
::v-deep .van-picker__cancel, ::v-deep .van-picker__confirm {
  padding: 20px;
  font-size: 30px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}

::v-deep .van-field__label {
  width: 120px !important;
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  ::v-deep .van-field__label {
    width: fit-content !important;
  }

  .van-cell {
    line-height: normal !important;
  }

  .main-box p {
    padding: 0 20px !important;
  }

  .bindCard {
    margin: 0 auto !important;
    margin-top: 20px !important;
    width: 80% !important;
    height: auto !important;
    padding: 15px !important;
  }

  .van-popup--bottom {
    max-width: 500px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  ::v-deep .van-picker__cancel, ::v-deep .van-picker__confirm {
    font-size: 20px !important;
  }

  ::v-deep .van-picker__toolbar {
    height: 50px !important;
  }

  .van-picker__cancel, .van-picker__confirm {
    height: auto !important;
  }

  ::v-deep .van-picker-column__item {
    font-size: 20px !important;
    height: 40px !important;
  }
}
</style>