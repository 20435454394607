<template>
	<div class="container page">
		<van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		
		<div class="right">
			<van-pull-refresh border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('xia-la-ji-ke-shua-xin')" :loosing-text="$t('shi-fang-ji-ke-shua-xin')" :loading-text="$t('jia-zai-zhong')">
				<van-grid :column-num="2" :gutter="10">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k" class="wrap_list">
						<div class="renzheng">{{ $t('yi-ren-zheng') }}</div>
						<div style="width: 50%; position: relative; padding-top: 50%; border-radius: 50%; overflow: hidden;">
							<van-image class="game_item_img" :src="v.img_url">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
						</div>
						<div class="info_wrpa">
							<div class="rig-name">{{ $t('yi-ming-vxuanfeiname', [v.xuanfei_name]) }}</div>
							<!-- <div class="rig-name">{{ $t('suo-zai-di-qu-vareaname', [v.area_name]) }}</div> -->
							<div>{{ $t('di-qu-vareaname', [v.area_name]) }}</div>
							<div class="rig-name">{{ $t('jian-jie') }}</div>
							<div style="margin-top: 10px; display: flex !important; align-items: center;">
								<span style='display: flex; align-items: center; margin-right: 10px'>
									<van-icon class="iconfont" class-prefix="icon" name="guankan_guankan" size='20px' style="margin-right: 2px" color="#9b9b9b" />{{v.view_m}}
								</span>
								<span style='display: flex; align-items: center;'>
									<van-icon class="iconfont" class-prefix="icon" name="xihuan" size='20px' color="#ff8d8c" />{{ v.like_m }}
								</span>
							</div>
						</div>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			vod_name: '',
			isLoading: false,
			datalist: []
		};
	},
	methods: {
		back() {
			this.$router.back();
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t('shua-xin-cheng-gong-1'));
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.datalist = res.data;
			});
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
	}
};
</script>

<style scoped>
::v-deep .van-nav-bar__title {
	color: #000;
}

.right {
	margin-top: 10px;
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
}
.game_item_img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 50%;
}
.van-image__img {
	width: 100%;
	height: 100%;
}
.rig-name {
	width: 100%;
	margin-top: 5px;
	font-size: 25px;
}
</style>
<style lang="scss" scoped>
.info_wrpa {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	margin-top: 24px;

	div {
		width: 100%;
		font-size: 24px;
		margin-top: 5px;
		color: #666666;
		display: -webkit-box;            /* 设置为弹性盒模型 */
		-webkit-box-orient: vertical;   /* 垂直方向排列 */
		-webkit-line-clamp: 1;          /* 限制为两行 */
		overflow: hidden;                /* 隐藏超出的内容 */
		text-overflow: ellipsis;        /* 省略号 */
	}
}
.wrap_list {
	border: 5px solid #ffd5db;
	border-radius: 10px;
	padding: 20px;
	background: #fff7f7;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;

	.renzheng {
		position: absolute;
		width: 100%;
		padding: 6px 0;
		background: #ff4d67;
		z-index: 9;
		color: #fff;
		transform: rotateZ(45deg) translate(-50%, -50%);
		top: 38%;
		left: 72%;
		text-align: center;
		font-size: 22px;
	}
}

::v-deep {
	.van-grid {
		display: grid !important;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		padding-left: 0 !important;
		padding: 20px !important;
	}

	.van-nav-bar__title {
		color: #fff !important; 
	}

	.van-grid-item__content {
		background: transparent !important;
		border: none !important;
	}
}
::v-deep .van-icon-arrow-left {
	color: #fff !important;
} 
</style>

<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  ::v-deep .van-grid {
	padding: 20px !important;
	grid-gap: 10px !important;
  }

  .wrap_list {
	border: 2px solid #ffd5db !important;
  }

  .right {
	margin-top: 0 !important;
  }

  .wrap_list .renzheng {
	font-size: 14px !important;
	padding: 5px 0 !important;
	top: 33% !important;
	left: 74% !important;
  }

  .wrap_list {
	padding: 20px !important;
	border-radius: 10px !important;
	margin-top: 0 !important;
  }

  .info_wrpa {
	margin-top: 20px !important;
  }

  .info_wrpa div {
	margin-top: 5px !important;
	font-size: 14px !important;
  }

  ::v-deep .van-loading__spinner {
    width: 25px !important;
    height: 25px !important;
  }
}
</style>