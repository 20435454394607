

<template>
  <div class="home-container">
    <van-nav-bar
      class="nav-bar"
      title=""
      fixed
    >
      <template slot="left">
        <van-icon name="/img/logo.png" class="logo" color="#4c364b" size='140px' />
      </template>
      <template slot="right">
        <div class="language">
          <van-icon class="iconfont" class-prefix="icon" name="yuyan" color="#ff5c74" size='24px' @click.stop="state = !state"/>
          <ul class='language_wrap' style="transform: translateX(100%)">
            <li :class='{ active: state }' :style="`--index:${1}`" @click.stop="handleSetLang('ja')">
              <van-icon name="/img/lang/ico_jp.png" color="#ff5c74" size='24px' />
              日本語
            </li>
            <li :class='{ active: state }' :style="`--index:${2}`" @click.stop="handleSetLang('ko')">
              <van-icon name="/img/lang/icon_kr.png" color="#ff5c74" size='24px' />
              한국인
            </li>
            <li :class='{ active: state }' :style="`--index:${3}`" @click.stop="handleSetLang('en')">
              <van-icon name="/img/lang/ico_en.png" color="#ff5c74" size='24px' />
              English
            </li>
            <li :class='{ active: state }' :style="`--index:${4}`" @click.stop="handleSetLang('zh')">
              <van-icon name="/img/lang/ico_cn.png" color="#ff5c74" size='24px' />
              简体中文
            </li>
            <li :class='{ active: state }' :style="`--index:${5}`" @click.stop="handleSetLang('zh-TW')">
              <van-icon name="/img/lang/ico_hk.png" color="#ff5c74" size='24px' />
              繁體中文
            </li>
          </ul> 
        </div>
      </template>
    </van-nav-bar>
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner" style="margin-bottom: 10px">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image> 
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#7e5678"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <!-- <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span style="white-space: nowrap">{{ $t('tui-jian-ren-wu') }}</span></div>
          <div @click="gotoMenu('/Game')">
            <span>查看更多</span> 
            <van-icon name="arrow" color="#979799" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border = false :column-num="3" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>大厅</span>
            </van-grid-item>
            <van-grid-item @click='handleGoTab("/Loading")'>
              <van-image class="game_item_img" src="/img/home/1.png">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{ $t('da-ting') }}</span>
            </van-grid-item>
            <van-grid-item @click='handleGoTab("/Choose")'>
              <van-image class="game_item_img" src="/img/home/2.png">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{ $t('yue-hui') }}</span>
            </van-grid-item>
            <van-grid-item @click='handleGoTab("/Video")'>
              <van-image class="game_item_img" src="/img/home/3.png">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{ $t('ying-yuan') }}</span>
            </van-grid-item>
            <van-grid-item @click='handleGoTab("/ServiceOnline")'>
              <van-image class="game_item_img" src="/img/home/4.png">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{ $t('ke-fu') }}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div> -->
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('xia-la-ji-ke-shua-xin')" :loosing-text="$t('shi-fang-ji-ke-shua-xin')" :loading-text="$t('jia-zai-zhong')">
        <div class="hot-recommend">
          <div class="hot-title-div">
            <div>
              <span style="white-space: nowrap">{{ $t('ren-qi-pai-hang') }}</span>
            </div>
            <!-- <div @click="handlePersonMore">
              <span>{{ $t('cha-kan-geng-duo') }}</span>
              <van-icon name="arrow" color="#979799" />
            </div> -->
          </div>
         
          <div class="movie_list_0">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v,key) in hotList" :key="key" >
                <van-image class="movie_cover" @click="toPerson(v)"  round :src="v.img_url" fit="cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.xuanfei_name}}</span>
                    <div style='display: flex; align-items: center;'>
                      <van-icon class="iconfont" class-prefix="icon" name="xihuan" size='20px' color="#ff8d8c" style="margin-right: 4px"/>{{ v.like_m }}
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="hot-title-div">
            <div>
              <span style="white-space: nowrap">{{ $t('re-men-tui-jian') }}</span></div>
            <!-- <div @click="handlePersonMore">
              <span>{{ $t('cha-kan-geng-duo-0') }}</span>
              <van-icon name="arrow" color="#979799" />
            </div> -->
          </div>
          <div v-if="personsList.length !== 0">
            <div class="movie_list_1">
              <div class="movie-list-item" v-for="(v,key) in personsList" :key="key" @click="toPerson(v)">
                <div class="movie_list_wrap">
                  <div class="renzheng">{{ $t('yi-ren-zheng') }}</div>
                  <div style="width: 100%; display: flex; justify-content: center;">
                    <div class="avatar_img" style="width: 50%; position: relative; padding-top: 50%;">
                      <van-image class="cover_img"  round :src="v.img_url" fit="cover">
                        <template v-slot:loading>
                          <van-loading type="circular"/>
                        </template>
                      </van-image>
                    </div>
                  </div>
                  <!-- <div style="text-align: center">ID: {{ v.id }}</div> -->
                  <div class="info_wrap">
                      <div>{{ $t('yi-ming-vxuanfeiname-0', [v.xuanfei_name]) }}</div>
                      <!-- <div>{{ $t('suo-zai-di-qu-vareaname-0', [v.area_name]) }}</div> -->
                      <div>{{ $t('di-qu-vareaname', [v.area_name]) }}</div>
                      <div>{{ $t('jian-jie') }}</div>
                      <div style="margin-top: 10px; display: flex !important; align-items: center;">
                        <span class="mz_title" style='display: flex; align-items: center; margin-right: 10px'>
                          <van-icon class="iconfont" class-prefix="icon" name="guankan_guankan" size='20px' style="margin-right: 2px" color="#9b9b9b" />{{v.view_m}}
                        </span>
                        <span class="mz_title" style='display: flex; align-items: center;'>
                          <van-icon class="iconfont" class-prefix="icon" name="xihuan" size='20px' color="#ff8d8c" />{{ v.like_m }}
                        </span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="hot-recommend-more" @click="handlePersonMore">{{ $t('cha-kan-geng-duo-1') }}</div> -->
          </div>
          <div class="empty" v-else>
            <van-empty :description="$t('zan-wu-geng-duo-shu-ju')" image-size="100px" :image="this.$store.getters.getBaseInfo.ico !== undefined ? this.$store.getters.getBaseInfo.ico : ''"/>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info_wrap {
  width: inherit;
  margin-top: 24px;
  
  div {
    width: 100%;
    font-size: 24px;
    margin-top: 5px;
    color: #666666;
    display: -webkit-box;            /* 设置为弹性盒模型 */
    -webkit-box-orient: vertical;   /* 垂直方向排列 */
    -webkit-line-clamp: 1;          /* 限制为两行 */
    overflow: hidden;                /* 隐藏超出的内容 */
    text-overflow: ellipsis;        /* 省略号 */
  }
}
.movie_list_wrap {
  border: 5px solid #ffd5db;
  border-radius: 10px;
  padding: 20px;
  background: #fff7f7;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .renzheng {
    position: absolute;
    width: 100%;
    padding: 6px 0;
    background: #ff4d67;
    z-index: 9;
    color: #fff;
    transform: rotateZ(45deg) translate(-50%, -50%);
    top: 42%;
    left: 68%;
    text-align: center;
    font-size: 24px;
  }
}
</style>

<script>
import NavBar from '../../common/NavBar.vue'
export default {
  components: { NavBar },
  data() {
    return {
      state: false,
      notice: this.$t('huo-qu-zhong'),
      banners: [{}],
      hotList: [{}, {}, {}],
      addressId: null,
      personsList: [{},{},{},{},{},{},{},{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 1,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1.05,
        speed:800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
    handleGoTab(url) {
      this.$router.push(url)
    },
    handleSetLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      this.state = false
    },
    toPerson(v) {
      if(!v.id) return
      this.$router.push({ path: '/profile?id=' + v.id });
    },
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!id) return
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    // 查看更多人气主播
    handlePersonMore() {
      this.$router.push(`/Choose`)
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('shua-xin-cheng-gong-0'));
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

      this.$http({
				method: 'get',
				url: 'xuanfeihot'
			}).then(res => {
        this.hotList = res.data
			})

      this.$http({
				method: 'get',
				url: 'xuanfeirandom'
			}).then(res => {
        this.personsList = res.data
			});
    },
    getNotice(data){
      this.notice = data?.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data?.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data?.movielist_1
    },
    getBanner(data){
      this.banners = data?.banners;
    },
    windowClick() {
      this.state = false
    }
  },
  destroyed() {
    window.removeEventListener('click', this.windowClick)
  },
  created() {
    window.addEventListener('click', this.windowClick)
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.nav-bar {
  background: #fff !important;
  z-index: 999999;
}
.linear-bg{
  height: 310px;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: 100%;
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -25%;
}

.notice-bar {
  box-shadow: 0 7px 7px #00000014;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
  margin-bottom: 20px;
  box-shadow: 0 7px 0 7px #f2f2f2;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 130px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #979799;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #979799;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background: linear-gradient(to bottom, #fff 20%, #f2f2f5 100%);
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
  padding: 0 10px;
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 0 20px;
  box-sizing: border-box;
  // width: calc(100% - 50px);
  // margin: 0 auto;
  // align-items: flex-start;
  // justify-content: flex-start;
  // flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width:100%;
  height:100%;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  // margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 100%;
}
.movie_list_1 .movie-list-item:nth-child(odd){
  // margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
  padding: 0 5px;
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding-left: 8px;
  font-size: 25px;
  text-overflow: ellipsis;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-size: 22px;
  text-overflow: ellipsis;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
  margin-top: 20px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

.movie_list_1 .empty {
  display: flex; 
  justify-content: center;
}
</style>
<style lang="scss" scoped>
.language {
  position: relative;
  padding-top: 1.5vw;

  .language_wrap {
    position: absolute;
    right: 0px;

    li {
      min-width: 23vw;
      color: #fff;
      background: linear-gradient(120deg, #212121, #313131);
      display: flex;
      align-content: center !important;
      justify-content: flex-start;
      color: #f3c7a2;
      padding-left: 10px;
      font-size: 3vw;
      cursor: pointer;
      margin-bottom: 0.367vw;
      transform: rotateY(90deg) translateX(-100%);
      transition-delay: calc(var(--index) * 0.05s) !important;
      transition: all 300ms linear;
      opacity: 0;
      -webkit-tap-highlight-color: transparent; /* 移除移动端点击高亮效果 */
      user-select: none; /* 禁用文本选择 */
      outline: none; /* 移除点击时的轮廓 */
      
      &.active {
        transform: rotateY(0) translateX(-100%) !important;
        opacity: 1;
      }

      ::v-deep .van-icon {
        margin-right: 1.333vw;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (min-width: 700px) {
  * {
    font-size: 16px !important;
  }

  .home-container {
    max-width: 500px !important;
    margin: 0 auto !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important
  }

  .nav-bar {
    line-height: auto !important;
    height: 50px !important
  }

  .language {
    padding-top: 0 !important;
  }

  .nav-bar {
    margin: 0 auto !important;
    max-width: 500px !important;
    left: 50% !important; 
    transform: translateX(-50%) !important;
  }

  .linear-bg {
    height: 200px !important;
  }

  ::v-deep .notice-swipe {
    height: 50px !important
  }

  ::v-deep .van-icon-bullhorn-o::before {
    transform: scale(0.9) !important;
  }

  .hot-title-div {
    height: 50px !important;
    margin-top: 0 !important;
    width: 100% !important;
    padding: 0 20px !important;
  }

  .hot-title-div > div:first-child span:before {
    height: 20px !important;
    width: 5px !important;
  }

  .van-grid {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .hot-items-div .game_item_img {
    width: 40px !important;
    height: 40px !important;
  }

  .hot-game .hot-items-div span {
    font-size: 16px !important;
  }

  .banner_swiper .swiper-slide {
    height: 200px !important;
  }

  .hot-game {
    box-shadow: none !important;
  }

  ::v-deep .van-grid-item__content {
    padding: 10px 0 20px !important;
  }

  .movie_cover {
    width: 98% !important;
    height: 200px !important;
  }

  .movie_list_0 {
    width: 100%;
    padding: 0 20px !important;
  }

  .movie_list_0 .movie-list-item-bottom {
    width: 98% !important;
    bottom: 25px !important; 
  }

  .movie_list_1 {
    padding: 0 20px !important;
  }

  .movie_list_wrap {
    border-radius: 10px !important;
    border: 2px solid #ffd5db !important;
  }

  .info_wrap div {
    font-size: 16px !important;
  }

  .movie_list_1 {
    grid-gap: 20px !important;
  }

  .movie_list_wrap .renzheng {
    top: 43% !important;
    left: 68% !important;
    font-size: 14px !important;
    padding: 5px 0 !important;
  }

  .movie_list_wrap {
    padding: 20px !important;
  }

  .home-scroll {
    padding-bottom: 0 !important;
  }

  .logo ::v-deep .van-icon__image {
    width: 120px !important;
    height: 120px !important;
  }

  ::v-deep .van-nav-bar__left, ::v-deep .van-nav-bar__right {
    padding: 0 10px !important;
  }

  .info_wrap {
    margin-top: 20px !important;
  }

  .van-icon__image {
    width: 25px !important;
    height: 25px !important;
  }

  .language .language_wrap li .van-icon {
    margin-right: 10px !important;
  }

  .language .language_wrap li {
    font-size: 12px !important;
    padding-left: 10px !important;
    min-width: 100px !important;
    height: 30px !important;
    line-height: 30px !important;
    margin-bottom: 1px !important;
  }

  .language .language_wrap {
    transform: translateX(100%) !important;
    right: 9px !important;
    top: 46px !important;
  }
  
  .language {
    position: static !important;
  }

  .movie_list_0 .movie_swiper .hot {
    width: 70px !important;
    border-top-left-radius: 10px;
  }

  .movie_cover {
    border-radius: 10px !important;
  }

  .avatar_img {
    padding-top: 40% !important;
    width: 40% !important;
  }

  .info_wrap div {
    font-size: 14px !important;
  }

  .mz_title {
    font-size: 14px !important;
  }

  .hot-game {
    margin-bottom: 0 !important;
  }

	.notice-bar {
		box-shadow: none !important;
	}

  .hot-game .hot-items-div span {
    margin-top: 10px !important;
  }

  ::v-deep .van-loading__spinner {
    width: 25px !important;
    height: 25px !important;
  }
}
</style>